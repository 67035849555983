import Estudio from "./Estudio"
import ConsultasApp from "../../../ConsultasApp"


class Consultas{
    static async get(fechas,senal){
        let data = []
        try{
            let array = await ConsultasApp.Get(`getestudios?inicio=${fechas.inicio}&fin=${fechas.final}`,senal)
            //let array = DB
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                data.push(new Estudio(element))
            }
            return data
        }
        catch(e){
            throw e
        }
    }
    /**
     * 
     * @param {Blob} informe 
     * @param {Blob} estudio 
     */
    static async cargarinforme(informe,estudio){
        let formdata = new FormData()
        formdata.append('files',informe)
        formdata.append('estudio',JSON.stringify(estudio))
        formdata.append('fechasubida',new Date())
        try{
            let res = await ConsultasApp.Post("cargarInforme",formdata)
            return (res.data)
        }
        catch(e){
            throw e
        }
    }
    /**
     * 
     * @param {Object} senal cancelacion de fetch
     */
    static async getcontactos(senal){
        try{
            let res = await ConsultasApp.Get("listaclientes",senal)
            return (res)
            //return DB1
        }
        catch(e){
            throw e
        }
    }
    static async sharecorreo(data){
        try{
            console.log("2222222222")
            let res = await ConsultasApp.Post("sharecorreo",data)
            return (res.data)
            //return DB1
        }
        catch(e){
            throw e
        }
    }
}

export default Consultas